import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallApiPost } from "../hooks/useApiPost";
import BarChart from "./BarChart/BartChart";
import type { ChartData } from "chart.js";
import { useContractedData } from "../hooks/useContractedData";
export interface CapacityChartProps {}

const CapacityChart: React.FC<CapacityChartProps> = () => {
  const [year, setYear] = useState(2023);
  const { months, years, revenueData } = useContractedData(year);
  const [employeeData, setEmployeeData] = useState<any>();
  const [chartData, setChartData] = useState<ChartData<"bar">>();

  const { sendData } = useCallApiPost();

  useEffect(() => {
    sendData(
      {
        query: `
          {
            boards(ids: 3033480445) {
             items  {
               id
               name
               state
               column_values {
                 id
                 description
                 title
                 text
               }         
             }
           }
         }
        `,
      },
      (data: any) => setEmployeeData(data?.data?.boards?.[0]?.items)
    );
  }, [sendData]);

  useEffect(() => {
    if (revenueData && employeeData) {
      const generatedChartData = {
        labels: months
          ?.filter((item) => item.year.getFullYear() === year)
          .map((item) => moment(item.year).format("MMM YY")),
        datasets: [
          {
            type: "line" as any,
            yAxisID: "y1",
            label: "Capacity",
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            fill: false,
            data: months?.map((item: any) =>
              employeeData
                .filter((employeeData2: any) => {
                  const startDate = moment(
                    employeeData2.column_values.find(
                      (item2: any) => item2.id === "date_1"
                    ).text,
                    "YYYY-MM-DD"
                  );
                  const endDate = moment(
                    employeeData2.column_values.find(
                      (item2: any) => item2.id === "date_13"
                    ).text,
                    "YYYY-MM-DD"
                  );
                  if (
                    (startDate.isSameOrBefore(moment(item.year)) ||
                      !startDate.isValid()) &&
                    (endDate.isSameOrAfter(moment(item.year)) ||
                      !endDate.isValid())
                  ) {
                    return employeeData;
                  } else return false;
                })
                .reduce((prevValue: number, currentValue: any) => {
                  return (
                    prevValue +
                    +currentValue.column_values.find(
                      (item2: any) => item2.id === "numbers9"
                    ).text
                  );
                }, 0)
            ),
          },
          {
            type: "bar" as const,
            label: "Contracted",
            backgroundColor: "rgba(86, 154, 252, .8)",
            data: months?.map((item: any) => item.hours),
            borderColor: "white",
            borderWidth: 2,
            yAxisID: "y",
          },
        ],
      };
      if (generatedChartData) {
        setChartData(generatedChartData);
      }
    }
  }, [employeeData, revenueData, months, year]);

  return chartData ? (
    <>
      {" "}
      <h4 style={{ textAlign: "center", margin: ".5rem" }}>Capacity chart</h4>
      {years?.map((yearItem) => (
        <button
          className={`${year === yearItem ? "button button-active" : "button"}`}
          onClick={() => setYear(yearItem)}
        >
          {yearItem}
        </button>
      ))}
      <BarChart data={chartData} />
    </>
  ) : null;
};

export default CapacityChart;
