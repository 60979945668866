import "./App.css";

import RevenueChart from "./components/RevenueChart";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CapacityChart from "./components/CapacityChart";

const App = () => {
  const [searchParams] = useSearchParams();
  const [appType, setAppType] = useState<number>();

  useEffect(() => {
    const appType = searchParams.get("appType");
    if (appType === "0") {
      setAppType(0);
    } else if (appType === "1") {
      setAppType(1);
    }
  }, [searchParams]);
  return (
    <>
      {appType === 0 ? <CapacityChart /> : null}
      {appType === 1 ? <RevenueChart /> : null}
    </>
  );
};

export default App;
