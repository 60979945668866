import React, { useRef } from "react";

import { useDetermineMobile } from "../../hooks/useDetermineMobile";

import { Bar } from "react-chartjs-2";
import { ChartData, Chart, registerables } from "chart.js";
Chart.register(...registerables);

const options: any = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Capacity chart",
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
      stacked: true,
    },
    y1: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
    },
    x: {
      stacked: true,
    },
  },
};

interface BarChartProps {
  data: ChartData<"bar", (number | null)[], unknown>;
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const { isMobile } = useDetermineMobile();
  const ref = useRef();
  return (
    <Bar
      ref={ref}
      height={isMobile ? undefined : 50}
      data={data}
      options={options}
    />
  );
};

export default BarChart;
