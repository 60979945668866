import { useState, useEffect } from "react";
import { dateParser } from "../utils/dateParser";
import { useCallApiPost } from "./useApiPost";

const monthNames = [
  "Januar",
  "Februar",
  "Marts",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
];

const generateRevenueData = (
  unhandledData: any,
  year: number
): {
  months: { year: Date; hours: number; revenue: number }[];
  years: number[];
} => {
  const months: { year: Date; hours: number; revenue: number }[] =
    monthNames.map((monthItem) => ({
      year:
        dateParser(`${monthItem} ${+year.toString().substring(2)}`) ||
        new Date(),
      hours: 0,
      revenue: 0,
    }));
  const years: number[] = [];
  if (unhandledData?.length > 0) {
    unhandledData.forEach((dataItem: any) => {
      const hourlyRate = dataItem?.column_values?.find(
        (x: any) => x?.title === "Timepris"
      )?.text;
      dataItem.column_values.forEach((item: any) => {
        const parsedDate = dateParser(item.title);
        if (parsedDate) {
          let foundMonth = months.find(
            (x) => x.year.getTime() === parsedDate.getTime()
          );
          if (foundMonth) {
            foundMonth.hours += +item.text;
            foundMonth.revenue += item.text * hourlyRate;
          } else {
            months.push({
              year: parsedDate,
              hours: +item.text,
              revenue: item.text * hourlyRate,
            });
          }
          if (!years.includes(parsedDate.getFullYear())) {
            years.push(parsedDate.getFullYear());
          }
        }
      });
    });
  }
  return { months, years };
};

export const useContractedData = (year: number) => {
  const { sendData } = useCallApiPost();
  const [revenueData, setRevenueData] = useState<any>();
  const [years, setYears] = useState<number[]>();
  const [months, setMonths] = useState<
    { year: Date; hours: number; revenue: number }[]
  >([]);

  useEffect(() => {
    sendData(
      {
        query: `
            {
            boards(ids: 3492688440) {
                name
                description
             items  {
               id
               name
               state
               column_values {
                 id
                 description
                 title
                 text
               }
               subitems
               {
                 id
                 name
               }
             }
            }
        }
              `,
      },
      (data: any) => setRevenueData(data?.data?.boards[0]?.items)
    );
  }, [sendData]);

  useEffect(() => {
    const generatedData = generateRevenueData(revenueData, year);
    setMonths(generatedData.months);
    setYears(generatedData.years);
  }, [revenueData, year]);

  return { revenueData, years, months, year };
};
