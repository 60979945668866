import React, { useEffect, useMemo, useState } from "react";
import type { ChartData } from "chart.js";
import "./RevenueChart.css";
import BarChart from "./BarChart/BartChart";
import moment from "moment";
import { useContractedData } from "../hooks/useContractedData";
import { useLoadplanData } from "../hooks/useLoadplan";
import AnnualRevenue from "./AnnualRevenue";

export interface RevenueChartProps {}

const stackColors = {
  Mangler: "#E2445C",
  "Aftale på plads": "#FDAB3D",
  Klaret: "#4AD69D",
  "Skal hyres": "#FCD84B",
  Igang: "#D3D3D4",
  Contracted: "rgba(86, 154, 252, .8)",
};

const RevenueChart: React.FC<RevenueChartProps> = () => {
  const [year, setYear] = useState(2023);
  const { months, years, revenueData } = useContractedData(year);
  const {
    months: loadMonths,
    years: loadYears,
    revenueData: loadData,
    statusTypes,
  } = useLoadplanData(year);
  const [chartData, setChartData] = useState<ChartData<"bar">>();
  useState<{ year: Date; hours: number; revenue: number }[]>();

  const totalRevenue = useMemo(() => {
    const revenueItem: { [key: string]: { hours: number; revenue: number } } =
      {};

    statusTypes.forEach(
      (statusItem) =>
        (revenueItem[statusItem as keyof typeof revenueItem] = {
          hours: 0,
          revenue: 0,
        })
    );

    revenueItem.Contracted = {
      revenue: months
        ?.filter((x) => x.year.getFullYear() === year)
        .reduce((currentValue, nextValue) => {
          return currentValue + +nextValue.revenue;
        }, 0),
      hours: 0,
    };

    const loadPlanTotal: { [key: string]: { revenue: number; hours: number } } =
      loadMonths
        ?.filter((x) => x.year.getFullYear() === year)
        .reduce<{ [key: string]: { revenue: number; hours: number } }>(
          (currentValue, nextValue) => {
            const newItem: {
              [key: string]: { revenue: number; hours: number };
            } = {};

            statusTypes.forEach((statusItem) => {
              newItem[statusItem] = { revenue: 0, hours: 0 };
              newItem[statusItem].revenue =
                (currentValue[statusItem]?.revenue || 0) +
                nextValue.data[statusItem]?.revenue;

              newItem[statusItem].hours =
                (currentValue[statusItem]?.hours || 0) +
                nextValue.data[statusItem]?.hours;
            });
            return newItem;
          },
          {}
        );
    return { ...revenueItem, ...loadPlanTotal };
  }, [loadMonths, months, statusTypes, year]);

  const totalHours = useMemo(() => {
    return months
      ?.filter((x) => x.year.getFullYear() === year)
      .reduce((currentValue, nextValue) => {
        return currentValue + +nextValue.hours;
      }, 0);
  }, [months, year]);

  useEffect(() => {
    if (loadData) {
    }
  }, [loadData, loadMonths]);

  useEffect(() => {
    if (revenueData) {
      const generatedChartData = {
        labels:
          months
            ?.filter((x) => x.year.getFullYear() === year)
            .map((x) => moment(x.year).format("MMMM")) || [],
        datasets: [
          {
            type: "line" as any,
            yAxisID: "y1",
            label: "Hours",
            borderColor: "#FF5AC4",
            borderWidth: 2,
            fill: false,
            data: months?.map((monthItem) => monthItem.hours) || [],
          },
          {
            type: "bar" as const,
            yAxisID: "y",
            xAxisID: "x",
            label: "Revenue",
            backgroundColor: "rgba(86, 154, 252, .8)",
            data: months?.map((monthItem) => monthItem.revenue) || [],
            borderColor: "white",
            borderWidth: 2,
          },
        ],
      };

      statusTypes?.forEach((statusItem) => {
        generatedChartData.datasets.push({
          type: "bar" as const,
          yAxisID: "y",
          xAxisID: "x",
          label: statusItem,
          backgroundColor:
            stackColors[statusItem as keyof typeof stackColors] || "blue",
          data:
            loadMonths?.map((monthItem: any) => {
              return monthItem.data[statusItem]?.revenue;
            }) || [],
          borderColor: "white",
          borderWidth: 2,
        });
      });
      if (generatedChartData) {
        setChartData(generatedChartData);
      }
    }
  }, [loadMonths, months, revenueData, statusTypes, year]);

  return chartData ? (
    <>
      <h4 style={{ textAlign: "center", margin: ".5rem" }}>Revenue chart</h4>
      {years?.map((yearItem) => (
        <button
          className={`${year === yearItem ? "button button-active" : "button"}`}
          onClick={() => setYear(yearItem)}
        >
          {yearItem}
        </button>
      ))}

      <BarChart data={chartData} />

      <AnnualRevenue data={totalRevenue} colors={stackColors} />
    </>
  ) : null;
};

export default RevenueChart;
