import axios, { CancelTokenSource } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

const useCallApiPost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const source = useRef<CancelTokenSource | null>(null);

  useEffect(
    () => () => {
      if (source.current !== null) source.current.cancel();
    },
    []
  );

  const sendData = useCallback(
    (data: any, callback: (data: any) => void): any => {
      setIsLoading(true);
      source.current = axios.CancelToken.source();
      if (data) {
        axios
          .post(`${process.env.REACT_APP_API_URL}`, data ? data : null, {
            cancelToken: source?.current.token,
            headers: {
              Authorization: `${process.env.REACT_APP_API_TOKEN}`,
            },
          })
          .then((res) => {
            setIsLoading(false);
            if (callback && res.data) {
              callback(res.data);
            }
            return res.data;
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      }
    },
    []
  );

  return { sendData, isLoading };
};

export { useCallApiPost };
