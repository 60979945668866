import React, { useCallback, useMemo, useState } from "react";
import "./AnnualRevenue.css";

export interface AnnualRevenueProps {
  colors: { [key: string]: string };
  data: { [key: string]: { hours: number; revenue: number } };
}

const AnnualRevenue: React.FC<AnnualRevenueProps> = ({ colors, data }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(["Contracted"]);

  const setItem = useCallback(
    (item: string) => {
      let newItems = [...selectedItems];

      if (newItems.includes(item)) {
        newItems = newItems.filter((x) => x !== item);
      } else {
        newItems.push(item);
      }

      setSelectedItems(newItems);
    },
    [selectedItems]
  );

  const totalRevenue = useMemo(() => {
    let number = 0;
    Object.keys(data).forEach((item) => {
      if (selectedItems.includes(item)) {
        number += data[item].revenue;
      }
    });
    return number;
  }, [data, selectedItems]);

  return (
    <div style={{ textAlign: "center" }}>
      <p style={{ margin: ".5rem" }}>Total annual revenue (DKK):</p>
      <p className="revenueNumber-container">
        {Object.keys(data).map((revenueItem) =>
          data[revenueItem].revenue > 0 ? (
            <button
              key={`revenueButtons-${revenueItem}`}
              onClick={() => setItem(revenueItem)}
              className="revenueNumber"
              style={{
                backgroundColor: colors[revenueItem as keyof typeof colors],
                borderBottom: `6px solid ${
                  selectedItems.includes(revenueItem)
                    ? "lightgreen"
                    : colors[revenueItem as keyof typeof colors]
                }`,
              }}
            >
              <p className="revenueNumber-label">{revenueItem}</p>
              <p className="revenueNumber-number">
                {data[revenueItem].revenue?.toLocaleString()}
              </p>
            </button>
          ) : null
        )}
      </p>
      <p className="revenue-total">
        {totalRevenue > 0 ? totalRevenue.toLocaleString() : null}
      </p>
    </div>
  );
};

export default AnnualRevenue;
