const monthNames = {
  1: "Januar",
  2: "Februar",
  3: "Marts",
  4: "April",
  5: "Maj",
  6: "Juni",
  7: "Juli",
  8: "August",
  9: "September",
  10: "Oktober",
  11: "November",
  12: "December",
};

export const dateParser = (date: string) => {
  const month = date.split(" ")[0];
  const year = "20" + date.split(" ")[1];

  let monthNumber = Object.keys(monthNames).find(
    (key) => monthNames[+key as keyof typeof monthNames] === month
  );
  if (monthNumber && monthNumber?.toString().length < 2) {
    monthNumber = `0${monthNumber}`;
  }
  if (monthNumber) {
    return new Date(+year, +monthNumber - 1, 1);
  }
  return null;
};
