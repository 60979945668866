import { useState, useEffect } from "react";
import { dateParser } from "../utils/dateParser";
import { useCallApiPost } from "./useApiPost";

const monthNames = [
  "Januar",
  "Februar",
  "Marts",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
];

const generateLoadplanData = (
  unhandledData: any,
  year: number
): {
  months: {
    year: Date;
    data: { [key: string]: { hours: number; revenue: number } };
  }[];
  years: number[];
  statusTypes: string[];
} => {
  const months: {
    year: Date;
    data: { [key: string]: { hours: number; revenue: number } };
  }[] = monthNames.map((monthItem) => ({
    year:
      dateParser(`${monthItem} ${+year.toString().substring(2)}`) || new Date(),
    data: {},
  }));
  const years: number[] = [];
  const statusTypes: string[] = [];
  if (unhandledData?.length > 0) {
    unhandledData.forEach((dataItem: any) => {
      const hourlyRate = dataItem?.column_values?.find(
        (x: any) => x?.title === "Timepris"
      )?.text;
      const status = dataItem?.column_values?.find(
        (x: any) => x?.title === "Status"
      )?.text;

      if (status && !statusTypes.includes(status)) {
        statusTypes.push(status);
      }

      dataItem.column_values.forEach((item: any) => {
        const parsedDate = dateParser(item.title);
        if (parsedDate) {
          let foundMonth = months.find(
            (x) => x.year.getTime() === parsedDate.getTime()
          );
          if (foundMonth) {
            if (!foundMonth?.data[status]) {
              foundMonth.data[status] = { hours: 0, revenue: 0 };
            }
            foundMonth.data[status].hours += +item.text;
            foundMonth.data[status].revenue += item.text * hourlyRate;
          } else {
            months.push({
              year: parsedDate,
              data: {
                [status]: {
                  hours: item.text,
                  revenue: item.text * hourlyRate,
                },
              },
            });
          }
          if (!years.includes(parsedDate.getFullYear())) {
            years.push(parsedDate.getFullYear());
          }
        }
      });
    });
  }
  return { months, years, statusTypes };
};

export const useLoadplanData = (year: number) => {
  const { sendData } = useCallApiPost();
  const [revenueData, setRevenueData] = useState<any>();
  const [years, setYears] = useState<number[]>();
  const [statusTypes, setStatusTypes] = useState<string[]>([]);
  const [months, setMonths] = useState<
    {
      year: Date;
      data: { [key: string]: { hours: number; revenue: number } };
    }[]
  >([]);

  useEffect(() => {
    sendData(
      {
        query: `
        {
          boards(ids: 3455595237) {
           items  {
             id
             name
             state
             column_values {
               id
               description
               title
               text
             }    
           }
         }                
       }
              `,
      },
      (data: any) => setRevenueData(data?.data?.boards[0]?.items)
    );
  }, [sendData]);

  useEffect(() => {
    const generatedData = generateLoadplanData(revenueData, year);
    setMonths(generatedData.months);
    setYears(generatedData.years);
    setStatusTypes(generatedData.statusTypes);
  }, [revenueData, year]);

  return { revenueData, years, months, year, statusTypes };
};
